<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 12:38:21
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="certification animated bounceInRight">
    <heada class="fixed-top"></heada>
    <div class="con">
        <div class="box">
            <p class="title">添加企业认证</p>
            <p class="heng"><span>企业信息</span></p>
            <dl class="dl1">
                <dd class="dd_left"><span>*</span>企业名称</dd>
                <dd class="dd_right"><input type="text" @focus="xinyong_cunzai1=false" @change="chakan_chongfu()" placeholder="请输入您所属公司营业执照上的公司名称" maxlength="46" v-model="name1" @keyup="keyu"><p class="p"><span>{{zishu_1}}</span>/46</p>
                    <p v-if="xinyong_cunzai1" class="tishi_xinxi animated fadeInRight">该企业已被填写</p>
                </dd>
                <dd class="dd_left"><span>*</span>统一社会信用代码</dd>
                <dd class="dd_right "><input type="text" @change="chakan_chongfu()" placeholder="请输入18位社会信用代码" @focus='xinyong_cunzai1=false' maxlength="18" v-model="xin_yong" @keyup="keyu2"><p class="p"><span>{{xinyong}}</span>/18</p></dd>
                <dd class="dd_left"><span>*</span>行业大类</dd>
                <dd class="dd_right">
                    <el-select v-model="hangye_id" class="renzheng_qiye_hangye" placeholder="请选择">
                        <el-option
                        v-for="item in hangye_list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </dd>
              <dd class="dd_clear"></dd>
                  <dd class="dd_left"><span>*</span>行业明细</dd>
                <dd class="dd_right"><input type="text"  maxlength="20" v-model="hangye_mingxi"><p class="p"><span>{{hangye_mingxi.length}}</span>/20</p></dd>
              <dd class="dd_clear"></dd>
               <dd class="dd_left"><span>*</span>上传营业执照</dd>
                <dd class="dd_right">
                    <img class="img1" v-if="tian" src="../assets/increase.png" alt="">
                    <p class="qi_p" v-if="tian">【认证企业专享】建议不超过5M</p>
                    <input class="shang" v-if="tian" type="file" @change="getImgBasea()">
                    <div v-if="!tian">
                        <div class="img2"  v-for="(i,index) in imgBase64" :key="index">
                            <span @click="shan_zhi">x</span>
                            <img :src="i" class="img3" alt="">
                        </div>
                    </div>
                </dd>
            </dl>
            <p class="heng"><span>法人信息</span></p>
            <div class="fa_box">
                <div class="fa_name" style="margin-right:0.28rem;"><span>*</span>法人姓名<input type="text" placeholder="请输入法人姓名" v-model="name" @keyup="keyu4"></div>
                <div class="fa_name"><span>*</span>手机号<input type="text" placeholder="请输入手机号码" v-model="phone" maxlength="11" @keyup="keyu3"><p class="p"><span>{{pholenth}}</span>/11</p></div>
            </div>
            <div class="shen_box">
                <p class="shen_li"><span>*</span>身份证照片</p>
                <ul>
                    <li>
                        <img v-if="tianb" src="../assets/shen_zheng.png" alt="">
                        <p v-if="tianb">上传身份证正面照片</p>
                        <input class="shang" type="file" @change="getImgBaseb()" v-if="tianb">
                        <div v-if="!tianb">
                            <div class="img2"  v-for="(i,index) in imgBase64b" :key="index">
                                <span @click="shan_zhib">x</span>
                                <img :src="i" class="img3" alt="">
                            </div>
                        </div>
                    </li>
                    <li>
                        <img v-if="tianc" src="../assets/shen_fan.png" alt="">
                        <p v-if="tianc">上传身份证反面照片</p>
                        <input class="shang" type="file" @change="getImgBasec()" v-if="tianc">
                        <div v-if="!tianc">
                            <div class="img2"  v-for="(i,index) in imgBase64c" :key="index">
                                <span @click="shan_zhic">x</span>
                                <img :src="i" class="img3" alt="">
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div :class="renzheng?'ren_no':'ren_ok'" @click="ren_sure">立即认证</div>
        </div>
    </div>
    <foote></foote>
  </div>
</template>

<script>
import heada from '../components/Heade'
import foote from '../components/Foote'
import { aut_ent_have_exist, add_ent_aut_info, query_user_aut_ent, update_ent_aut_info } from '../api/api.js'
import { showLoading, hideLoading } from '../Js/loading'
export default {
  name: 'certification',
  data () {
    return {
      renzheng: true,
      zishu_1: 0,
      name1: '',
      xinyong: 0,
      xin_yong: '',
      phone: '',
      pholenth: 0,
      name: '',
      imgBase64: [],
      imgBase64b: [],
      imgBase64c: [],
      lei: '',
      xin: '',
      leib: '',
      xinb: '',
      leic: '',
      xinc: '',
      tian: true,
      tianb: true,
      tianc: true,
      user_id: '',
      ent_id: '',
      pc_bianji_ing: '',
      xinyong_cunzai1: false,
      xinyong_cunzai2: false,
      hangye_list: [
        {
          name: '软件和信息技术服务业',
          id: '12'
        },
        {
          name: '建筑业',
          id: '3'
        },
        {
          name: '批发业',
          id: '4'
        },
        {
          name: '零售业',
          id: '5'
        },
        {
          name: '租凭和商务服务业',
          id: '15'
        },
        {
          name: '交通运输业',
          id: '6'
        },
        {
          name: '工业',
          id: '2'
        },
        {
          name: '仓储业',
          id: '7'
        },
        {
          name: '邮政业',
          id: '8'
        },
        {
          name: '住宿业',
          id: '9'
        },
        {
          name: '餐饮业',
          id: '10'
        },
        {
          name: '信息传输业',
          id: '11'
        },
        {
          name: '房地产开发经营',
          id: '13'
        },
        {
          name: '物业管理',
          id: '14'
        },
        {
          name: '农、林、牧、渔业',
          id: '1'
        }
      ],
      hangye_id: '',
      hangye_mingxi: ''
    }
  },
  components: { heada, foote },
  created () {
    if (sessionStorage.getItem('pc_mlbb_xinxi') != null) {
      this.user_id = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_xinxi'))).user_id
    }
    if (sessionStorage.getItem('pc_bianji_ent_id') != null && sessionStorage.getItem('pc_bianji_ent_id') != undefined) {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_bianji_ent_id'))
    }
    if (sessionStorage.getItem('pc_bianji_ing') != '' && sessionStorage.getItem('pc_bianji_ing') != undefined) {
      this.pc_bianji_ing = JSON.parse(JSON.parse(sessionStorage.getItem('pc_bianji_ing')))
    }
  },
  mounted () {
    this.jichu()
  },
  watch: {
    imgBase64 () {
      if (this.imgBase64.length > 0) {
        this.tian = false
      } else {
        this.tian = true
      }
      this.pan()
    },
    imgBase64b () {
      if (this.imgBase64b.length > 0) {
        this.tianb = false
      } else {
        this.tianb = true
      }
      this.pan()
    },
    imgBase64c () {
      if (this.imgBase64c.length > 0) {
        this.tianc = false
      } else {
        this.tianc = true
      }
      this.pan()
    }
  },
  methods: {
    // 刚开始的基础信息
    jichu () {
      if (this.pc_bianji_ing == 1) {
        query_user_aut_ent({
          data: {
            user_id: this.user_id,
            ent_id: this.ent_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10101) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.name1 = date.ent_name
            this.zishu_1 = date.ent_name.length
            this.xin_yong = date.uscc
            this.xinyong = date.uscc.length
            this.imgBase64.push(date.license)
            this.name = date.lar_name
            this.phone = date.lar_mobile
            this.pholenth = date.lar_mobile.length
            var zheng = date.lar_idcard_url.split(' ')
            this.imgBase64b.push(zheng[0])
            this.imgBase64c.push(zheng[1])
            this.hangye_id = date.industry
            this.hangye_mingxi = date.industry_detail.length != 0 ? date.industry_detail : ''
          }
        })
      }
    },
    // 判断
    pan () {
      if (this.name1.length > 0 && this.xin_yong.length == 18 && this.imgBase64.length > 0 && this.name.length > 0 && /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.phone) && this.imgBase64b.length > 0 && this.imgBase64c.length > 0 && this.hangye_id.length >= 0) {
        this.renzheng = false
      } else {
        this.renzheng = true
      }
    },
    // 监听
    keyu () {
      this.zishu_1 = this.name1.length
      this.pan()
    },
    keyu2 () {
      this.xinyong = this.xin_yong.length
      this.pan()
    },
    keyu3 () {
      this.pholenth = this.phone.length
      this.pan()
    },
    keyu4 () {
      this.pan()
    },
    // 营业执照
    getImgBasea () {
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      _this.lei = file.name.substring(file.name.lastIndexOf('.'), file.name.length)

      // 转base64
      reader.onload = function (e) {
        _this.imgBase64.push(e.target.result)
        _this.xin = e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        )
      }
      reader.readAsDataURL(file)
    },
    // 删掉营业执照
    shan_zhi () {
      this.imgBase64 = []
      this.lei = ''
      this.xin = ''
      this.pan()
    },
    // 身份证正面
    getImgBaseb () {
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      _this.leib = file.name.substring(file.name.lastIndexOf('.'), file.name.length)

      // 转base64
      reader.onload = function (e) {
        _this.imgBase64b.push(e.target.result)
        _this.xinb = e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        )
      }
      reader.readAsDataURL(file)
    },
    // 删掉营业执照
    shan_zhib () {
      if (this.pc_bianji_ing == 1) {
        this.imgBase64b = []
        this.imgBase64c = []
        this.pan()
      } else {
        this.imgBase64b = []
        this.leib = ''
        this.xinb = ''
        this.pan()
      }
    },
    // 身份证反面
    getImgBasec () {
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      _this.leic = file.name.substring(file.name.lastIndexOf('.'), file.name.length)

      // 转base64
      reader.onload = function (e) {
        _this.imgBase64c.push(e.target.result)
        _this.xinc = e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        )
      }
      reader.readAsDataURL(file)
    },
    // 删掉营业执照
    shan_zhic () {
      if (this.pc_bianji_ing == 1) {
        this.imgBase64b = []
        this.imgBase64c = []
        this.pan()
      } else {
        this.imgBase64c = []
        this.leic = ''
        this.xinc = ''
        this.pan()
      }
    },
    // 认证
    ren_sure () {
      if (this.renzheng == false) {
        if (this.xinyong_cunzai1) {
          this.$message({
            message: '已存在该公司信息',
            type: 'error'
          })
        } else {
          if (this.pc_bianji_ing == 1) {
            sessionStorage.setItem('pc_bianji_ing', '')
            let shenfz = ''
            let shenff = ''
            if (this.xin == '') {
              this.xin = null
            }
            if (this.lei == '') {
              this.lei = null
            }
            if (this.xinb == '') {
              this.xinb = null
              shenfz = null
            } else {
              shenfz = this.xinb + ' ' + this.xinc
              shenff = this.leib + ' ' + this.leic
            }
            if (this.leib == '') {
              this.leib = null
              shenff = null
            } else {
              shenfz = this.xinb + ' ' + this.xinc
              shenff = this.leib + ' ' + this.leic
            }
            if (this.xinc == '') {
              this.xinc = null
              shenfz = null
            } else {
              shenfz = this.xinb + ' ' + this.xinc
              shenff = this.leib + ' ' + this.leic
            }
            if (this.leic == '') {
              this.leic = null
              shenff = null
            } else {
              shenfz = this.xinb + ' ' + this.xinc
              shenff = this.leib + ' ' + this.leic
            }
            showLoading()
            update_ent_aut_info({
              data: {
                ent_id: this.ent_id,
                ent_name: this.name1,
                uscc: this.xin_yong,
                license_data: this.xin,
                license_data_type: this.lei,
                lar_name: this.name,
                lar_mobile: this.phone,
                user_id: this.user_id,
                lar_idcard_data: shenfz,
                lar_idcard_data_type: shenff,
                industry: this.hangye_id,
                industry_detail: this.hangye_mingxi.length != 0 ? this.hangye_mingxi : null
              }
            }).then(res => {
              console.log(res)
              hideLoading()
              if (res.data.code == 10115) {
                this.$router.push('/ctft')
              } else if (res.data.code == 10116) {
                this.$message({
                  message: '信息提交失败，请稍后重试',
                  type: 'error'
                })
              }
            })
          } else {
            let shenfz = ''
            shenfz = this.xinb + ' ' + this.xinc
            let shenff = ''
            shenff = this.leib + ' ' + this.leic
            showLoading()
            add_ent_aut_info({
              data: {
                ent_name: this.name1,
                uscc: this.xin_yong,
                license_data: this.xin,
                license_data_type: this.lei,
                lar_name: this.name,
                lar_mobile: this.phone,
                lar_idcard_data: shenfz,
                lar_idcard_data_type: shenff,
                user_id: this.user_id,
                industry: this.hangye_id,
                industry_detail: this.hangye_mingxi.length != 0 ? this.hangye_mingxi : null
              }
            }).then(res => {
              console.log(res)
              hideLoading()
              if (res.data.code == 10085) {
                this.$router.push('/ctft')
              } else if (res.data.code == 10086) {
                this.$message({
                  message: '信息提交失败，请稍后重试',
                  type: 'error'
                })
              } else if (res.data.code == 600) {
                this.$message({
                  message: '已存在该公司信息',
                  type: 'error'
                })
              }
            })
          }
        }
      } else {
        this.$message({
          message: '请填写完整信息',
          type: 'error'
        })
      }
    },
    chakan_chongfu () {
      if ((/^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$/.test(this.xin_yong)) || this.name1.length != 0) {
        aut_ent_have_exist({
          data: {
            uscc: this.xin_yong.length != 0 ? this.xin_yong : null,
            ent_name: this.name1.length != 0 ? this.name1 : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 600) {
            this.xinyong_cunzai1 = true
          } else if (res.data.code == 700) {
            this.xinyong_cunzai1 = false
            // 不存在
          }
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.certification {
  background:#E7E8EB;
  padding-top: 1.87rem;
  height: 100%;
  .con{
      width: 100%;
      flex: 1;
      height:10.57rem;
      padding-bottom: 0.8rem;
      background:#E7E8EB;
      .box{
          height:10.73rem;
          width:8.61rem;
          margin: 0 auto;
          background: #fff;
          padding: 0 0.5rem;
          border-radius: 0.04rem;
          .title{
              color:#323C48;
              font-size: 0.18rem;
              margin:0 0 0.24rem 0.2rem;
              padding-top: 0.24rem;
          }
          .heng{
              height:0.42rem;
              width:8.6rem;
              background: #E7E8EB;
            //   line-height: 0.42rem;
                display: flex;
                align-items: center;
              margin-bottom: 0.35rem;
              span{
                  font-size: 0.14rem;
                  color:#1A2533;
                  margin-left: 0.18rem;
              }
          }
          .dl1{
              height:3rem;
              .dd_left{
                  float: left;
                  text-align: right;
                  width:24%;
                  line-height: 0.44rem;
                  font-size: 0.14rem;
                  color:#4C4A4D;
                  margin-right:0.2rem;
                  margin-bottom: 0.22rem;
                  span{
                      font-size: 0.14rem;
                      color:#F96C6C;
                      line-height: 0.44rem;
                  }
              }
              .dd_right{
                  float: left;
                  width: 70%;
                  margin-bottom: 0.22rem;
                  border:0.01rem solid transparent;
                  position: relative;
                  input{
                      width:100%;
                      height:0.44rem;
                      border-radius: 0.06rem;
                      border:0.01rem solid #979797;
                      padding: 0 0.14rem;
                    font-size: 0.14rem;
                  }
                  input::-webkit-input-placeholder {
                    color: #ADADAD;
                    letter-spacing:0.05rem;
                    }
                    input:-moz-placeholder {
                    color: #ADADAD;
                    letter-spacing:0.05rem
                    }
                    input::-moz-placeholder {
                    color: #ADADAD;
                    letter-spacing:0.05rem
                    }
                    input:-ms-input-placeholder {
                    color: #ADADAD;
                    letter-spacing:0.05rem
                    }
                  .img1{
                      height:0.72rem;
                      width:0.72rem;
                      float: left;
                  }
                  .qi_p{
                      font-size: 0.14rem;
                      color:#A9A9A9;
                      float: left;
                      margin-top:0.54rem;
                  }
                  .p{
                      position: absolute;
                      right:0;
                      top:0.11rem;
                      font-size: 0.18rem;
                      color:#676669;
                      span{
                          font-size: 0.18rem;
                          color:#FC0506;
                      }
                  }
                  .shang{
                      position: absolute;
                      height:0.72rem;
                      width:0.72rem;
                      padding: 0;
                      left:0;
                      opacity: 0;
                  }
                  .img2{
                      height:1.05rem;
                      width:1.59rem;
                      float: left;
                      position: relative;
                      .img3{
                            height:1.05rem;
                            width:1.59rem;
                      }
                      span{
                          height:0.2rem;
                          width:0.2rem;
                          display: block;
                          border-radius: 50%;
                          position: absolute;
                          right:-0.08rem;
                          top:-0.08rem;
                          background: #888888;
                          color:#fff;
                          line-height: 0.18rem;
                          text-align: center;
                      }
                  }
              }
              .dd_clear{
                clear:both;
              }
          }
          .fa_box{
              display: flex;
              align-items: center;
              position: relative;
              margin-left: 0.32rem;
              div{
                  display: flex;
                  align-items: center;
                  font-size: 0.14rem;
                  color: #4C4A4D;
                  line-height: 0.44rem;
                  margin-bottom: 0.58rem;
                  margin-left: 0.32rem;
                  span{
                      line-height: 0.44rem;
                      color:#F96C6C;
                      font-size: 0.14rem;
                  }
                  input{
                      height:0.44rem;
                      padding: 0 0.14rem;
                      width:2.62rem;
                      border:0.01rem solid #979797;
                      border-radius: 0.06rem;
                      margin-left: 0.05rem;
                      font-size: 0.14rem;
                  }
                  input::-webkit-input-placeholder {
                    color: #ADADAD;
                    letter-spacing:0.05rem
                    }
                    input:-moz-placeholder {
                    color: #ADADAD;
                    letter-spacing:0.05rem
                    }
                    input::-moz-placeholder {
                    color: #ADADAD;
                    letter-spacing:0.05rem
                    }
                    input:-ms-input-placeholder {
                    color: #ADADAD;
                    letter-spacing:0.05rem
                    }
              }
              .p{
                      position: absolute;
                      right:0.4rem;
                      font-size: 0.18rem;
                      color:#676669;
                      span{
                          font-size: 0.18rem;
                          color:#FC0506;
                      }
                  }
          }
          .shen_box{
              .shen_li{
                  font-size: 0.14rem;
                  color:#4C4A4D;
                  margin-left:0.48rem;
                  span{
                      font-size: 0.14rem;
                      color:#F96C6C;
                  }
              }
              ul{
                  display: flex;
                  justify-content: center;
                  position: relative;
                  li{
                      margin: 0 0.56rem;
                      img{
                          height:1.05rem;
                          width:1.59rem;
                      }
                      p{
                          font-size: 0.14rem;
                          color:#A9A9A9;
                          text-align: center;
                          margin-top:0.35rem;
                      }
                      .shang{
                          position: absolute;
                          height:1.05rem;
                          width:1.59rem;
                          top:0;
                          opacity: 0;
                      }
                      .img2{
                        height:1.05rem;
                        width:1.59rem;
                        float: left;
                        position: relative;
                        .img3{
                                height:1.05rem;
                                width:1.59rem;
                        }
                        span{
                            height:0.2rem;
                            width:0.2rem;
                            display: block;
                            border-radius: 50%;
                            position: absolute;
                            right:-0.08rem;
                            top:-0.08rem;
                            background: #888888;
                            color:#fff;
                            line-height: 0.18rem;
                            text-align: center;
                        }
                    }
                  }
              }
          }
          .ren_no{
              height:0.45rem;
              width:3.25rem;
              border-radius: 0.06rem;
              color:#fff;
              line-height: 0.45rem;
              text-align: center;
              margin:0 auto;
              margin-top:0.68rem;
              background-color: #F96C6C;
              cursor:pointer;
          }
          .ren_ok{
              height:0.45rem;
              width:3.25rem;
              border-radius: 0.06rem;
              color:#fff;
              line-height: 0.45rem;
              text-align: center;
              margin:0 auto;
              margin-top:0.68rem;
              background-color: #FE2020;
              cursor:pointer;
          }
      }
  }

}
.dd_right.boxs-size{
  box-sizing: border-box;
}
</style>
